/** @deprecated */
const events = {
  toast: {
    success: "success-toast",
    error: "error-toast",
  },
  layout: {
    closeSidebar: "layout-sidebarClose",
  },
  overlay: {
    show: "show-overlay-message",
    hide: "hide-overlay-message",
  },
  account: {
    logout: "logout",
  },
  accounts: {
    reload: "accounts-reload",
  },
  notifications: {
    reload: "notifications-reload",
    allRead: "notifications-all-read",
  },
  keypress: {
    arrowRight: "key-arrowRight",
    arrowLeft: "key-arrowLeft",
  },
};

export { events };
