import { RouteName } from "@/router/routes";
import { RouteLocationRaw } from "vue-router";

export const createNotificationsListLink = (): RouteLocationRaw => ({ name: RouteName.NotificationsList });
export const createNewNotificationsLink = (): RouteLocationRaw => ({ name: RouteName.NotificationNew });
export const createNotificationDetailLink = (notificationId: number): RouteLocationRaw => ({ name: RouteName.NotificationDetail, params: { id: notificationId } });

export const createUserCollectionLink = (userId: number): RouteLocationRaw => ({
  name: RouteName.UserCollection,
  params: {
    userId,
  },
});

export const createStatisticsLink = (): RouteLocationRaw => ({ name: RouteName.Statistics });
