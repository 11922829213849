<template>
  <div class="page-wrapper">
    <error-layout v-if="layout == 'error'" />
    <admin-layout v-else-if="layout == 'admin'" />
    <public-layout v-else />
  </div>

  <overlay-message />
  <toasts />
  <ConfirmDialog />
</template>

<script>
import { UserService } from "@/services/user/UserService";
import OverlayMessage from "@/components/deprecated/OverlayMessage.vue";
import Toasts from "@/components/deprecated/Toasts.vue";
import ConfirmDialog from "primevue/confirmdialog";
import ErrorLayout from "./module/error/component/errorLayout/ErrorLayout.vue";
import PublicLayout from "./module/public/component/publicLayout/PublicLayout.vue";
import AdminLayout from "./module/admin/component/adminLayout/AdminLayout.vue";

export default {
  data() {
    return {
      layout: null,
    };
  },
  mounted() {
    this.checkLogin();
    this.onPageChange();

    window.eventBus.on(events.account.logout, () => {
      this.onLogout();
    });
    window.eventBus.on("login-failed", () => {
      this.onLoginFailed();
    });
    window.eventBus.on("error", (e) => {
      this.onError(e);
    });
    window.addEventListener("keyup", this.onKeyUp);
  },
  unmounted() {
    window.eventBus.off(events.account.logout);
    window.eventBus.off("login-failed");
    window.eventBus.off("error");
    window.removeEventListener("keyup", this.onKeyUp);
  },
  watch: {
    $route() {
      this.onPageChange();
    },
  },
  methods: {
    onPageChange() {
      const page = this.$route.meta;

      this.layout = page.layout;

      if (page.title) {
        document.title = page.title;
      }
    },
    onLogout() {
      this.$router.push({ name: "public-login" });
    },
    onLoginFailed() {
      this.inPublicSection = true;
    },
    onError(e) {
      window.eventBus.emit(events.toast.error, e.message);
    },
    checkLogin() {
      const token = localStorage.getItem("token");

      if (token) {
        const logout = this.$route.meta.logout && this.$route.meta.logout === true;

        if (logout) {
          UserService.logout();
        } else {
          window.eventBus.emit(events.overlay.show, { text: "Přihlašuji....", spinner: true });

          UserService.setCredentials({ token: token });
          UserService.authenticate().then(() => {
            window.eventBus.emit(events.overlay.hide);
          });
        }
      }
    },
    onKeyUp(e) {
      if (e.key == "ArrowRight") {
        window.eventBus.emit(events.keypress.arrowRight);
      } else if (e.key == "ArrowLeft") {
        window.eventBus.emit(events.keypress.arrowLeft);
      }
    },
  },
  components: {
    OverlayMessage,
    Toasts,
    ConfirmDialog,
    ErrorLayout,
    PublicLayout,
    AdminLayout,
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  font: var(--font-size) / var(--line-height) var(--primary-font-family);
}

body {
  background: linear-gradient(to bottom, var(--dark-green) 0, var(--light-green) 150vh);
  background-attachment: fixed;
  min-height: 100vh;
  font: var(--font-size) / var(--line-height) var(--primary-font-family);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

::selection {
  background: var(--primary-color);
  color: #fff;
}
</style>
