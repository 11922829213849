import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { logout } from "@/services/user";
import { Response401Exception, Response403Exception } from "./apiExceptions";
import { ElMessage } from "element-plus";

interface RetryConfig extends AxiosRequestConfig {
  retryCount?: number;
}

// Array of request sending fail reasons for which we attempt to resend an API request.
const refetchableCodes = [AxiosError.ERR_NETWORK, AxiosError.ETIMEDOUT, AxiosError.ECONNABORTED];

// Array of HTTP statuses for which for which we attempt to resend an API request.
const refetchableHttpStatuses = [502, 504];

// Array of HTTP methods for which we attempt to resend an API request.
const refetchableHttpMethods = ["GET"];

// How many times should we attempt to send request again after fail?
// Keep in mind in the end the request can be sent (RefetchMaxAttempts + 1) times. Original request + RefetchMaxAttempts...
const RefetchMaxAttempts = 2;

// Try another request attempt after x miliseconds.
const RefetchDelay = 1000;

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 15000,
});

axios.interceptors.response.use(
  async (response: AxiosResponse) => {
    const status = response.status;
    if (status >= 200 && status <= 299) {
      return Promise.resolve(response);
    } else if (status === 400) {
      return Promise.reject(response);
    } else {
      const text = getErrorCode2text(response);
      return Promise.reject(new Error(text));
    }
  },

  (response: AxiosError) => {
    const status = getErrorResponseStatus(response);
    if (status === 401) {
      return handle401error();
    }
    if (status === 403) {
      ElMessage({
        type: "error",
        message: "Pro tuto operaci nemáš dotatečná oprávnění!",
      });
      Promise.reject(new Response403Exception());
    }
    if (isRefetchableResponse(response)) {
      return handleRefetchableResponseError(response);
    }

    return Promise.reject(response);
  }
);

/** For given axios error - should we try to send request again? */
function isRefetchableResponse(errorResponse: AxiosError): boolean {
  const method = getErrorResponseMethod(errorResponse);
  if (!refetchableHttpMethods.includes(method)) {
    return false;
  }

  const code = errorResponse.code;
  if (code && refetchableCodes.includes(code)) {
    return true;
  }

  const status = getErrorResponseStatus(errorResponse);
  if (status && refetchableHttpStatuses.includes(status)) {
    return true;
  }

  return false;
}

function handle401error(): Promise<AxiosError> {
  logout(false);
  throw new Response401Exception();
}

function handleRefetchableResponseError(errorResponse: AxiosError): Promise<AxiosResponse> {
  const config: RetryConfig = errorResponse.config as RetryConfig;
  if (!config || !config.retryCount) {
    config.retryCount = 0;
  }

  if (config.retryCount < RefetchMaxAttempts) {
    config.retryCount++;

    return new Promise<AxiosResponse>((resolve) => {
      setTimeout(() => resolve(axios(config)), RefetchDelay);
    });
  } else {
    return Promise.reject(errorResponse);
  }
}

function getErrorResponseStatus(errorResponse: AxiosError): number | undefined {
  return errorResponse.response?.status;
}

function getErrorResponseMethod(errorResponse: AxiosError): string {
  return errorResponse.request.method;
}

const getErrorCode2text = (response: AxiosResponse): string => {
  const code = response.status; // HTTP status code.

  let message = "Request Error";

  switch (code) {
    case 400:
      message = "Request Error";
      break;
    case 401:
      message = "Unauthorized, please login";
      break;
    case 403:
      message = "Forbidden";
      break;
    case 404:
      message = "Not Found";
      break;
    case 408:
      message = "Request Timeout";
      break;
    case 500:
      message = "Internal Server Error";
      break;
    case 501:
      message = "Not Implemented";
      break;
    case 502:
      message = "Bad Gateway";
      break;
    case 503:
      message = "Service Unavailable";
      break;
    case 504:
      message = "Gateway Timeout";
      break;
    case 505:
      message = "HTTP Version Not Supported";
      break;
    default:
      message = "Unknown error";
  }

  return message;
};

export { axios };
