export enum RouteName {
  NotificationsList = "NotificationsList",
  NotificationNew = "NotificationNew",
  NotificationDetail = "NotificationDetail",
  UserCollection = "UserCollection",
  Statistics = "Statistics",
}

export const routes = [
  {
    path: "/notifikace",
    meta: {
      title: "Notifikace",
      layout: "admin",
      right: "notifications",
    },
    children: [
      {
        name: RouteName.NotificationsList,
        path: "/",
        components: { mainContent: () => import("@/module/admin/module/notifications/view/NotificationsList.vue") },
      },
      {
        name: RouteName.NotificationNew,
        path: "nova",
        components: { mainContent: () => import("@/module/admin/module/notifications/view/NotificationDetail.vue") },
      },
      {
        name: RouteName.NotificationDetail,
        path: ":id",
        components: { mainContent: () => import("@/module/admin/module/notifications/view/NotificationDetail.vue") },
      },
    ],
  },

  {
    path: "/users",
    meta: {
      title: "Uživatelé",
      layout: "admin",
      right: "accounts",
    },
    children: [
      {
        path: ":userId",
        components: { mainContent: () => import("@/module/admin/module/accounts/component/AccountLayout.vue") },
        children: [
          {
            name: RouteName.UserCollection,
            path: "collection",
            components: { tab: () => import("@/views/user/Collection.vue") },
          },
        ],
      },
    ],
  },

  {
    path: "/statistics",
    meta: {
      title: "Statistiky",
      layout: "admin",
    },
    components: { mainContent: () => import("@/views/Statistics.vue") },
    name: RouteName.Statistics,
  },
];
